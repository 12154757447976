export const environment = {
  production: true,
  apiUrl: 'https://api.stage.edg-pro.com',
  frontUrl: 'https://stage.edg-pro.com',
  portalUrl: 'https://stage.edg-pro.com',
  domain: 'stage.edg-pro.com',
  tokenName: 'token.stage.edg-pro.com',
  sentry: {
    dsn: 'https://20805b0e29824dd98f2464b8b3e3aa77@o4504261033000960.ingest.sentry.io/4504261035163648',
    env: 'stage',
  },
  binaries: {
    installer: 'https://cdn.stage.edg-pro.com/binaries/Installer.zip',
    moduleBuilder: 'https://cdn.stage.edg-pro.com/binaries/ModuleBuilder.zip',
  },
  contact: 'eduardbcomabsoft@gmail.com',
};
